













































































































import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { formatCurrency, letterKeydown } from "@/validator/globalvalidator";
import { ResponseListProductUom, UomConversions } from "@interface/uom.interface";
import { masterUomServices } from "@service/master-uom.service";

const { mapMutations, mapState } = createNamespacedHelpers("productStoreV2");

interface IRowConversion {
  key: string;
  conversionRate: number | null;
  purchasePrice: number | null;
  salePrice: number | null;
  unitUom: string | null;
  uomConversionId: string | null;
  cogs: number | null;
  bottomPrice: number | null;
}

export default Vue.extend({
  name: "ProductUOMConversion",
  data() {
    return {
      dtSource: [] as IRowConversion[],
      selectedRowKeys: [] as string[],
      dtListUOM: {} as ResponseListProductUom,
    };
  },
  computed: {
    ...mapState({
      storeProduct: (state: any) => state.theProduct
    })
  },
  mounted() {
    this.getListUOM();
    this.fillTable();
  },
  methods: {
    ...mapMutations([
      "SET_PRODUCT"
    ]),
    letterKeydown,
    formatCurrency,
    addRow(): void {
      const { dtSource } = this;
      const newRow: IRowConversion = {
        key: Date.now().toString(),
        conversionRate: null,
        purchasePrice: 0,
        salePrice: 0,
        unitUom: "",
        uomConversionId: null,
        cogs: 0,
        bottomPrice: 0
      };
      this.dtSource = [...dtSource, newRow];
      this.commitProduct();
    },
    onRowSelect(rowKeys: string[]): void {
      this.selectedRowKeys = rowKeys;
    },
    deleteRow(): void {
      const { dtSource } = this;
      const ids = dtSource.filter(data => this.selectedRowKeys.includes(data.key));
      this.dtSource = dtSource.filter(data => !this.selectedRowKeys.includes(data.key));
      this.selectedRowKeys = [];
      this.commitProduct();
      this.SET_PRODUCT({
        ...this.storeProduct,
        ...{
          deletedUomConversionIds: ids.map(x => x.uomConversionId)
        }
      });
    },
    getListUOM(): void {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        sorts: "createdDate:desc"
      };
      masterUomServices.listMasterUom(params)
      .then(res => {
        this.dtListUOM = res;
      });
    },
    commitProduct(): void {
      const dtUomConversion = {
        uomConversions: [...this.dtSource]
      };
      this.SET_PRODUCT({
        ...this.storeProduct,
        ...dtUomConversion
      });
    },
    fillTable(): void {
      const { dtSource } = this;
      const uomConv = this.storeProduct.uomConversions as UomConversions[];
      const newRow: IRowConversion[] = uomConv.map(x => {
        return {
          key: x.uomConversionId,
          ...x
        };
      });
      this.dtSource = [...dtSource, ...newRow];
    },
    onblur(value: number | string, key: string, column: string): void {
      value = value.toString();
      const newData = [...this.dtSource];
      const target = newData.filter(item => key === item.key)[0];
      if (target) {
        target[column] = formatCurrency(value);
        this.dtSource = newData;
      }
    },
    getCheckboxProps(record) {
      const props = {
        disabled: record.unitUom === this.storeProduct.baseUnit
      };
      return {
        props
      };
    }
  },
});
