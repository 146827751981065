var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        [
          _c(
            "a-table",
            {
              attrs: {
                "data-source": _vm.dtSource,
                "row-selection": {
                  selectedRowKeys: _vm.selectedRowKeys,
                  onChange: _vm.onRowSelect,
                  getCheckboxProps: _vm.getCheckboxProps
                }
              }
            },
            [
              _c(
                "a-table-column",
                {
                  key: "unitUom",
                  attrs: { "data-index": "unitUom", width: 150 },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "default-value": record.unitUom,
                                "dropdown-match-select-width": false
                              },
                              on: { change: _vm.commitProduct },
                              model: {
                                value: record.unitUom,
                                callback: function($$v) {
                                  _vm.$set(record, "unitUom", $$v)
                                },
                                expression: "record.unitUom"
                              }
                            },
                            _vm._l(_vm.dtListUOM.data, function(unit) {
                              return _c(
                                "a-select-option",
                                { key: unit.unit, attrs: { value: unit.unit } },
                                [_vm._v(" " + _vm._s(unit.unit) + " ")]
                              )
                            }),
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_uom")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "bottomPrice",
                  attrs: { "data-index": "bottomPrice" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("currency")(record.bottomPrice)) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_base_price")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "purchasePrice",
                  attrs: { "data-index": "purchasePrice", width: 250 },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("a-input", {
                            attrs: {
                              placeholder: _vm.$t("lbl_type_here"),
                              prefix: "Rp"
                            },
                            on: {
                              input: _vm.commitProduct,
                              blur: function($event) {
                                return _vm.onblur(
                                  text,
                                  record.key,
                                  "purchasePrice"
                                )
                              },
                              keydown: _vm.letterKeydown
                            },
                            model: {
                              value: record.purchasePrice,
                              callback: function($$v) {
                                _vm.$set(record, "purchasePrice", $$v)
                              },
                              expression: "record.purchasePrice"
                            }
                          })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_purchase_price")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "salePrice",
                  attrs: { "data-index": "salePrice", width: 250 },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("a-input", {
                            attrs: {
                              placeholder: _vm.$t("lbl_type_here"),
                              prefix: "Rp"
                            },
                            on: {
                              input: _vm.commitProduct,
                              blur: function($event) {
                                return _vm.onblur(text, record.key, "salePrice")
                              },
                              keydown: _vm.letterKeydown
                            },
                            model: {
                              value: record.salePrice,
                              callback: function($$v) {
                                _vm.$set(record, "salePrice", $$v)
                              },
                              expression: "record.salePrice"
                            }
                          })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_sales_price")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "cogs",
                  attrs: { "data-index": "cogs" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm._f("currency")(record.cogs)) + " "
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_cogs")))
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-col",
        [
          _c(
            "a-space",
            [
              _c(
                "a-button",
                { attrs: { type: "danger" }, on: { click: _vm.deleteRow } },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: { icon: "plus", type: "primary" },
                  on: { click: _vm.addRow }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }